import React, { Component } from "react";
import config from "../../config/config.json";

import styles from "../../styles/index.module.css";
import "./borrowMultiplySection.css";

class BorrowMultiplySection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedNavbarItem: "multiply",

      cardCss: "bm-card",
      mobNavbarCss: "mobile-not-sticky-navbar",
      mobNavbarBgCss: "",
    };
  }

  getPoolIcon = (type) => {
    const icon = require(`../../assets/SVGs/pools/${type}`).default;
    return icon;
  };

  getAssetIcon = (type) => {
    const icon = require(`../../assets/PNGs/assets/${type}`).default;
    return icon;
  };

  handleScroll = () => {
    if (window.scrollY > 580) {
      this.setState({
        cardCss: "bm-card-on-scroll",
      });
      if (window.scrollY < 582) {
        this.setState({
          cardCss: "bm-card",
        });
      }
    } else if (window.scrollY < 580) {
      this.setState({
        cardCss: "bm-card",
      });
    }
  };

  handleScrollMobile = () => {
    if (window.scrollY > 610) {
      this.setState({
        mobNavbarCss: "mobile-sticky-navbar",
        mobNavbarBgCss: "mobile-sticky-navbar-bg",
      });
      if (window.scrollY > 2000) {
        this.setState({
          mobNavbarCss: "mobile-not-sticky-navbar",
          mobNavbarBgCss: "",
        });
      }
    } else if (window.scrollY < 612) {
      this.setState({
        mobNavbarCss: "mobile-not-sticky-navbar",
        mobNavbarBgCss: "",
      });
    }
  };

  componentDidMount = async () => {
    // window.addEventListener("scroll", this.handleScroll);
    // window.addEventListener("scroll", this.handleScrollMobile);
  };

  componentWillUnmount() {
    // window.removeEventListener("scroll", this.handleScroll);
    // window.removeEventListener("scroll", this.handleScrollMobile);
  }

  render() {
    var width = window.innerWidth;
    const selectedNavbarItemStyle = {
      backgroundColor: "#FFF",
    };
    const selectedNavbarItemTextStyle = {
      background: "linear-gradient(90deg, #E926C3 0%, #FF4D86 100%)",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
    };
    return (
      <>
        {width > 1299 ? (
          <div className={styles.borrowMultiplySection}>
            <div className={`${styles.navbar} ${styles.bg}`}>
              <div
                className={`${styles.navbarItem} ${styles.bg} ${styles.button}`}
                style={
                  this.state.selectedNavbarItem === "multiply"
                    ? selectedNavbarItemStyle
                    : {}
                }
                onClick={() =>
                  this.setState({
                    selectedNavbarItem: "multiply",
                  })
                }
              >
                <div
                  className={`${styles.navbarItemText} ${styles.labelMRegular} ${styles.primaryText}`}
                  style={
                    this.state.selectedNavbarItem === "multiply"
                      ? selectedNavbarItemTextStyle
                      : {}
                  }
                >
                  Multiply
                </div>
              </div>
              <div
                className={`${styles.navbarItem} ${styles.bg} ${styles.button}`}
                style={
                  this.state.selectedNavbarItem === "borrow"
                    ? selectedNavbarItemStyle
                    : {}
                }
                onClick={() =>
                  this.setState({
                    selectedNavbarItem: "borrow",
                  })
                }
              >
                <div
                  className={`${styles.navbarItemText} ${styles.labelMRegular} ${styles.primaryText}`}
                  style={
                    this.state.selectedNavbarItem === "borrow"
                      ? selectedNavbarItemTextStyle
                      : {}
                  }
                >
                  Borrow
                </div>
              </div>
            </div>
            {this.state.selectedNavbarItem === "borrow" ? (
              <div
                className={`${styles.secondaryText} ${"bm-subheader-section"}`}
              >
                Borrow USDR with 0% interest and earn yield on your collateral.
                <br />
                Swap your newly minted USDR for any other stablecoin.
              </div>
            ) : (
              <div
                className={`${styles.secondaryText} ${"bm-subheader-section"}`}
              >
                Multiply your exposure to your favorite crypto assets while
                boosting the yield exponentially.
              </div>
            )}
            <div className={styles.borrowMultiplyCardsSection}>
              {Object.keys(config.cards[this.state.selectedNavbarItem]).map(
                (pool) => (
                  <div
                    className={`${this.state.cardCss} ${styles.whiteBg} ${styles.elevationLow}`}
                  >
                    <div className={styles.cardLabelSection}>
                      <div
                        className={`${styles.cardLabel} ${styles.lightGradientBg}`}
                      >
                        <div
                          className={`${styles.gradientText}  ${styles.labelSRegular}`}
                        >
                          {
                            config.cards[this.state.selectedNavbarItem][pool]
                              .label
                          }
                        </div>
                      </div>
                    </div>
                    <div className={styles.cardPoolIconSection}>
                      <img
                        src={this.getPoolIcon(
                          config.cards[this.state.selectedNavbarItem][pool].icon
                        )}
                        alt="pool-icon"
                        className={styles.cardPoolIcon}
                      />
                    </div>
                    <div
                      className={`${styles.headingS} ${styles.primaryText} ${styles.cardHeader}`}
                    >
                      {config.cards[this.state.selectedNavbarItem][pool].header}
                    </div>
                    <div
                      className={`${styles.labelMRegular} ${styles.secondaryText} ${styles.cardText}`}
                    >
                      {config.cards[this.state.selectedNavbarItem][pool].text}
                    </div>
                    <div className={styles.cardAssetsBox}>
                      <div className={styles.cardAssetsRow}>
                        {Object.keys(
                          config.cards[this.state.selectedNavbarItem][pool]
                            .assetIcons
                        ).map((i) => (
                          <div className={styles.cardAssetsIconSection}>
                            <img
                              src={this.getAssetIcon(
                                config.cards[this.state.selectedNavbarItem][
                                  pool
                                ].assetIcons[i]
                              )}
                              alt="asset-icon"
                              className={styles.cardAssetsIcon}
                            />
                          </div>
                        ))}
                      </div>
                      <div
                        className={`${styles.labelSRegular} ${styles.secondaryText} ${styles.cardBoxText}`}
                      >
                        Supported assets
                      </div>
                    </div>
                    {this.state.selectedNavbarItem === "borrow" ? (
                      <div className={styles.cardInfoRow}>
                        <div className={styles.cardInfoCell}>
                          <div
                            className={`${styles.labelMRegular} ${styles.secondaryText}`}
                          >
                            APY
                          </div>
                          <div
                            className={`${styles.headingS} ${styles.primaryText}`}
                          >
                            {config.cards["borrow"][pool].APY}
                          </div>
                        </div>
                        <div className={styles.cardInfoCell}>
                          <div
                            className={`${styles.labelMRegular} ${styles.secondaryText}`}
                          >
                            Collateral Ratio
                          </div>
                          <div
                            className={`${styles.headingS} ${styles.primaryText}`}
                          >
                            {config.cards["borrow"][pool].collateralRatio}
                          </div>
                        </div>
                        <div className={styles.cardInfoCell}>
                          <div
                            className={`${styles.labelMRegular} ${styles.secondaryText}`}
                          >
                            Interest
                          </div>
                          <div
                            className={`${styles.headingS} ${styles.primaryText}`}
                          >
                            {config.cards["borrow"][pool].interest}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.cardInfoRow}>
                        <div className={styles.cardInfoCell}>
                          <div
                            className={`${styles.labelMRegular} ${styles.secondaryText}`}
                          >
                            APY up to
                          </div>
                          <div
                            className={`${styles.headingS} ${styles.primaryText}`}
                          >
                            {config.cards["multiply"][pool].upTo}
                          </div>
                        </div>
                        <div className={styles.cardInfoCell}>
                          <div
                            className={`${styles.labelMRegular} ${styles.secondaryText}`}
                          >
                            Max Multiply
                          </div>
                          <div
                            className={`${styles.headingS} ${styles.primaryText}`}
                          >
                            {config.cards["multiply"][pool].maxMultiply}
                          </div>
                        </div>
                        <div className={styles.cardInfoCell}>
                          <div
                            className={`${styles.labelMRegular} ${styles.secondaryText}`}
                          >
                            Interest
                          </div>
                          <div
                            className={`${styles.headingS} ${styles.primaryText}`}
                          >
                            {config.cards["multiply"][pool].interest}
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.selectedNavbarItem === "borrow" ? (
                      <div
                        className={`${"bm-button soon"} ${styles.cardButton} ${this.state.button} ${styles.gradientBg}`}
                      >
                        <div
                          className={`${"bm-button-text soon"} ${styles.labelMRegular} ${styles.whiteText}`}
                        >
                          {/* <span>Borrow</span> */}
                          Borrow
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`${"bm-button soon"} ${styles.cardButton} ${this.state.button} ${styles.gradientBg}`}
                      >
                        <div
                          className={`${"bm-button-text soon"} ${styles.labelMRegular} ${styles.whiteText}`}
                        >
                          {/* <span>Multiply</span> */}
                          Multiply
                        </div>
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        ) : (
          <div className={styles.mobBorrowMultiplySection}>
            <div className={this.state.mobNavbarBgCss} />
            <div
              className={`${styles.mobNavbar} ${styles.bg}
            ${this.state.mobNavbarCss}`}
            >
              <div
                className={`${styles.navbarItem} ${styles.bg} ${styles.button}`}
                style={
                  this.state.selectedNavbarItem === "multiply"
                    ? selectedNavbarItemStyle
                    : {}
                }
                onClick={() =>
                  this.setState({
                    selectedNavbarItem: "multiply",
                  })
                }
              >
                <div
                  className={`${styles.navbarItemText} ${styles.labelMRegular} ${styles.primaryText}`}
                  style={
                    this.state.selectedNavbarItem === "multiply"
                      ? selectedNavbarItemTextStyle
                      : {}
                  }
                >
                  Multiply
                </div>
              </div>
              <div
                className={`${styles.navbarItem} ${styles.bg} ${styles.button}`}
                style={
                  this.state.selectedNavbarItem === "borrow"
                    ? selectedNavbarItemStyle
                    : {}
                }
                onClick={() =>
                  this.setState({
                    selectedNavbarItem: "borrow",
                  })
                }
              >
                <div
                  className={`${styles.navbarItemText} ${styles.labelMRegular} ${styles.primaryText}`}
                  style={
                    this.state.selectedNavbarItem === "borrow"
                      ? selectedNavbarItemTextStyle
                      : {}
                  }
                >
                  Borrow
                </div>
              </div>
            </div>
            {this.state.selectedNavbarItem === "borrow" ? (
              <div
                className={`${
                  styles.primaryText
                } ${"mob-bm-subheader-section"}`}
              >
                Borrow USDR with 0% interest and earn yield on your collateral.{" "}
                Swap your newly minted USDR for any other stablecoin.
              </div>
            ) : (
              <div
                className={`${
                  styles.primaryText
                } ${"mob-bm-subheader-section"}`}
              >
                Multiply your exposure to your favorite crypto assets while
                boosting the yield exponentially.
              </div>
            )}
            <div className={styles.mobBorrowMultiplyCardsSection}>
              {Object.keys(config.cards[this.state.selectedNavbarItem]).map(
                (pool) => (
                  <div
                    className={`${"mob-bm-card"} ${styles.whiteBg} ${
                      styles.elevationLow
                    }`}
                  >
                    <div className={styles.mobCardLabelSection}>
                      <div
                        className={`${styles.mobCardLabel} ${styles.lightGradientBg}`}
                      >
                        <div
                          className={`${styles.gradientText}  ${styles.labelSRegular}`}
                        >
                          {
                            config.cards[this.state.selectedNavbarItem][pool]
                              .label
                          }
                        </div>
                      </div>
                    </div>
                    <div className={styles.mobCardPoolIconSection}>
                      <img
                        src={this.getPoolIcon(
                          config.cards[this.state.selectedNavbarItem][pool].icon
                        )}
                        alt="pool-icon"
                        className={styles.mobCardPoolIcon}
                      />
                    </div>
                    <div
                      className={`${styles.headingS} ${styles.primaryText} ${styles.cardHeader}`}
                    >
                      {config.cards[this.state.selectedNavbarItem][pool].header}
                    </div>
                    <div
                      className={`${styles.labelMRegular} ${styles.secondaryText} ${styles.cardText}`}
                    >
                      {config.cards[this.state.selectedNavbarItem][pool].text}
                    </div>
                    <div className={styles.mobCardAssetsBox}>
                      <div className={styles.cardAssetsRow}>
                        {Object.keys(
                          config.cards[this.state.selectedNavbarItem][pool]
                            .assetIcons
                        ).map((i) => (
                          <img
                            src={this.getAssetIcon(
                              config.cards[this.state.selectedNavbarItem][pool]
                                .assetIcons[i]
                            )}
                            alt="asset-icon"
                            className={styles.cardAssetsIcon}
                          />
                        ))}
                      </div>
                      <div
                        className={`${styles.labelSRegular} ${styles.secondaryText} ${styles.cardBoxText}`}
                      >
                        Supported assets
                      </div>
                    </div>
                    {this.state.selectedNavbarItem === "borrow" ? (
                      <div className={styles.cardInfoRow}>
                        <div className={styles.cardInfoCell}>
                          <div
                            className={`${styles.labelMRegular} ${styles.secondaryText}`}
                          >
                            APY
                          </div>
                          <div
                            className={`${styles.headingS} ${styles.primaryText}`}
                          >
                            {config.cards["borrow"][pool].APY}
                          </div>
                        </div>
                        <div className={styles.cardInfoCell}>
                          <div
                            className={`${styles.labelMRegular} ${styles.secondaryText}`}
                          >
                            Collateral Ratio
                          </div>
                          <div
                            className={`${styles.headingS} ${styles.primaryText}`}
                          >
                            {config.cards["borrow"][pool].collateralRatio}
                          </div>
                        </div>
                        <div className={styles.cardInfoCell}>
                          <div
                            className={`${styles.labelMRegular} ${styles.secondaryText}`}
                          >
                            Interest
                          </div>
                          <div
                            className={`${styles.headingS} ${styles.primaryText}`}
                          >
                            {config.cards["borrow"][pool].interest}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.cardInfoRow}>
                        <div className={styles.cardInfoCell}>
                          <div
                            className={`${styles.labelMRegular} ${styles.secondaryText}`}
                          >
                            APY up to
                          </div>
                          <div
                            className={`${styles.headingS} ${styles.primaryText}`}
                          >
                            {config.cards["multiply"][pool].upTo}
                          </div>
                        </div>
                        <div className={styles.cardInfoCell}>
                          <div
                            className={`${styles.labelMRegular} ${styles.secondaryText}`}
                          >
                            Max Multiply
                          </div>
                          <div
                            className={`${styles.headingS} ${styles.primaryText}`}
                          >
                            {config.cards["multiply"][pool].maxMultiply}
                          </div>
                        </div>
                        <div className={styles.cardInfoCell}>
                          <div
                            className={`${styles.labelMRegular} ${styles.secondaryText}`}
                          >
                            Interest
                          </div>
                          <div
                            className={`${styles.headingS} ${styles.primaryText}`}
                          >
                            {config.cards["multiply"][pool].interest}
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.selectedNavbarItem === "borrow" ? (
                      <div
                        className={`${"bm-button soon"} ${styles.mobCardButton} ${styles.button} ${styles.gradientBg}`}
                      >
                        <div
                          className={`${"bm-button-text soon"}${styles.labelMRegular} ${styles.whiteText}`}
                        >
                          <span>Borrow</span>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`${"bm-button soon"} ${styles.mobCardButton} ${styles.button} ${styles.gradientBg}`}
                      >
                        <div
                          className={`${"bm-button-text soon"} ${styles.labelMRegular} ${styles.whiteText}`}
                        >
                          <span>Multiply</span>
                        </div>
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default BorrowMultiplySection;
