import React, { Component } from "react";
import { connect } from "react-redux";
import config from "../../config/config.json";

import radarLogo from "../../assets/logos/radar.svg";
import menuIcon from "../../assets/SVGs/menu.svg";
import closeIcon from "../../assets/SVGs/close.svg";
import discordIcon from "../../assets/SVGs/socials/discord.svg";
import linkedinIcon from "../../assets/SVGs/socials/linkedin.svg";
import telegramIcon from "../../assets/SVGs/socials/telegram.svg";
import twitterIcon from "../../assets/SVGs/socials/twitter.svg";
import styles from "../../styles/index.module.css";
import "./header.css";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headerWrapperCss: "header-wrapper",
      headerBlurCss: "",
      headerContentCss: "header-content",
      blockchainDropdownCss: "blockchain-dropdown",
      headerBackButtonCss: "header-back-button",
      headerBlockchainButtonCss: "header-blockchain-button",
      headerAddressButtonCss: "header-address-button",
      headerAmountSectionCss: "header-amount-section",
      disconnectDropdownCss: "disconnect-dropdown",

      mobileMenu: false,
    };

    this.toPage = this.toPage.bind(this);
  }

  toPage = (path) => {
    this.props.history.push(path);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  toPageMobile = (path) => {
    this.props.history.push(path);
    window.scrollTo({
      top: 0,
      left: 0,
    });
  };

  handleScroll = () => {
    if (window.scrollY > 16) {
      this.setState({
        headerWrapperCss: "header-wrapper-on-scroll",
        headerContentCss: "header-content-on-scroll",
        headerBlurCss: "header-blur-on-scroll",
      });
    } else if (window.scrollY < 16) {
      this.setState({
        headerWrapperCss: "header-wrapper",
        headerContentCss: "header-content",
        headerBlurCss: "",
      });
    }
  };

  componentDidMount = async () => {
    window.addEventListener("scroll", this.handleScroll);
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    var width = window.innerWidth;
    const Mailto = ({ email, subject = "", body = "", children }) => {
      let params = subject || body ? "?" : "";
      if (subject) params += `subject=${encodeURIComponent(subject)}`;
      if (body)
        params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

      return <a href={`mailto:${email}${params}`}>{children}</a>;
    };
    return (
      <>
        {width > 1299 ? (
          <>
            <div className={`${this.state.headerWrapperCss}`}>
              <div className={`${this.state.headerContentCss}`}>
                <div className="header-layout">
                  <img
                    src={radarLogo}
                    alt="radar-logo"
                    className="header-logo"
                    onClick={() => this.toPage("/")}
                  />
                  <div className="header-links-section">
                    <a
                      href="https://radar.blog/"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        className={`${
                          styles.paragraphM
                        } ${"header-link-text"} ${styles.primaryText}`}
                      >
                        Blog
                      </div>
                    </a>
                    <a
                      href="https://app.radar.global/"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        className={`${
                          styles.button
                        } ${"header-cw-button soon"} ${styles.gradientBg} ${
                          styles.labelMRegular
                        } ${styles.whiteText}`}
                      >
                        {/* <span>Launch app</span> */}
                        Launch app
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {this.state.mobileMenu === false ? (
              <div className="mob-header-wrapper">
                <div className="mob-header-layout">
                  <img
                    src={radarLogo}
                    alt="radar-logo"
                    className="mob-header-logo"
                    onClick={() => this.toPageMobile("/")}
                  />
                  <img
                    src={menuIcon}
                    alt="menu"
                    className="mob-header-menu-icon"
                    onClick={() =>
                      this.setState({
                        mobileMenu: true,
                      })
                    }
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="mob-header-wrapper">
                  <div className="mob-header-layout">
                    <img
                      src={radarLogo}
                      alt="radar-logo"
                      className="mob-header-logo"
                      onClick={() => this.toPageMobile("/")}
                    />
                    <img
                      src={closeIcon}
                      alt="close"
                      className="mob-header-menu-icon"
                      onClick={() =>
                        this.setState({
                          mobileMenu: false,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="mob-menu-popup-wrapper">
                  <div
                    className="mob-menu-close-wrapper"
                    onClick={() =>
                      this.setState({
                        mobileMenu: false,
                      })
                    }
                  />
                  <div className="mob-menu-popup-box">
                    <div
                      className={`${styles.headingXS} ${
                        styles.primaryText
                      } ${"mob-header-links-header"}`}
                    >
                      Features
                    </div>
                    {Object.keys(config.footer["features"]).map((i) => (
                      <div
                        className={`${"mob-header-link-text soon"} ${
                          styles.paragraphM
                        } ${styles.primaryText}`}
                      >
                        <a
                          href={config.footer["features"][i].link}
                          target="_blank"
                          style={{ textDecoration: "none", color: "#2E3338" }}
                          className={`${styles.paragraphM} ${styles.primaryText}`}
                        >
                          {/* <span>{config.footer["features"][i].text}</span> */}
                          {config.footer["features"][i].text}
                        </a>
                      </div>
                    ))}
                    <div
                      className={`${styles.headingXS} ${
                        styles.primaryText
                      } ${"mob-header-links-header2"}`}
                    >
                      Resources
                    </div>
                    <div
                      className={`${styles.paragraphM} ${
                        styles.primaryText
                      } ${"mob-header-link-text"}`}
                      onClick={() => this.toPageMobile("/terms-and-conditions")}
                    >
                      Terms and Conditions
                    </div>
                    <Mailto
                      email="contact@radar.global"
                      subject=""
                      body=""
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        className={`${styles.paragraphM} ${
                          styles.primaryText
                        } ${"mob-header-mail-text"}`}
                      >
                        contact@radar.global
                      </div>
                    </Mailto>
                    <div className="mob-header-socials-section">
                      <a
                        href="https://t.me/radarprotocol"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <img
                          src={telegramIcon}
                          alt="telegram"
                          className="mob-header-social-icon"
                        />
                      </a>
                      <a
                        href="https://twitter.com/radarprotocol"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <img
                          src={twitterIcon}
                          alt="twitter"
                          className="mob-header-social-icon"
                        />
                      </a>
                      <a
                        href="https://discord.com/invite/radar"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <img
                          src={discordIcon}
                          alt="discord"
                          className="mob-header-social-icon"
                        />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/radarprotocol/"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <img
                          src={linkedinIcon}
                          alt="linkedin"
                          className="mob-header-social-icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
