import React, { Component } from "react";

import Footer from "../../layouts/footer/Footer";

import radarLines from "../../assets/SVGs/radar-circle.svg";
import chevronRightIcon from "../../assets/SVGs/chevron-right.svg";
import borrowIcon from "../../assets/SVGs/borrow.svg";
import multiplyIcon from "../../assets/SVGs/multiply.svg";
import farmIcon from "../../assets/SVGs/farm.svg";
import stabilizerIcon from "../../assets/SVGs/stabilizer.svg";
import stakeIcon from "../../assets/SVGs/stake.svg";
import styles from "../../styles/index.module.css";
import "./exploreSection.css";

class ExploreSection extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const Mailto = ({ email, subject = "", body = "", children }) => {
      let params = subject || body ? "?" : "";
      if (subject) params += `subject=${encodeURIComponent(subject)}`;
      if (body)
        params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

      return <a href={`mailto:${email}${params}`}>{children}</a>;
    };

    var width = window.innerWidth;
    return (
      <>
        {width > 1299 ? (
          <div className="explore-wrapper">
            <div className="explore-radar-circle-svg-section">
              <img
                src={radarLines}
                alt="radar-lines-svg"
                className="explore-radar-circle-svg"
              />
            </div>
            <div className="explore-layout">
              <div className="explore-gradient-overlay" />
              {/* <div className="explore-orange-overlay" />
              <div className="explore-pink-overlay" />
              <div className="explore-yellow-overlay" /> */}
            </div>
            <div className="explore-content">
              <div
                className={`${"explore-header"} ${styles.headingXXL} ${
                  styles.primaryText
                }`}
              >
                Have some questions?
              </div>
              <div className="explore-cards-section">
                <div className="explore-card">
                  <div
                    className={`${"explore-card-header"} ${styles.headingS} ${
                      styles.primaryText
                    }`}
                  >
                    Support
                  </div>
                  <div className="explore-card-subheader-section">
                    <div
                      className={`${"explore-card-subheader"} ${
                        styles.labelMRegular
                      } ${styles.secondaryText}`}
                    >
                      Contact us if you have any questions.
                    </div>
                  </div>
                  <Mailto
                    email="contact@radar.global"
                    subject=""
                    body=""
                    style={{ textDecoration: "none" }}
                  >
                    <div className="explore-card-link">
                      <div
                        className={`${"explore-card-link-text"} ${
                          styles.labelMRegular
                        } ${styles.gradientText}`}
                      >
                        Contact us
                      </div>
                      <img
                        src={chevronRightIcon}
                        alt="chevron-right-icon"
                        className="explore-card-link-icon"
                      />
                    </div>
                  </Mailto>
                  <a
                    href="https://t.me/radarprotocol"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="explore-card-link">
                      <div
                        className={`${"explore-card-link-text"} ${
                          styles.labelMRegular
                        } ${styles.gradientText}`}
                      >
                        Telegram
                      </div>
                      <img
                        src={chevronRightIcon}
                        alt="chevron-right-icon"
                        className="explore-card-link-icon"
                      />
                    </div>
                  </a>
                  <a
                    href="https://twitter.com/radarprotocol"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="explore-card-link">
                      <div
                        className={`${"explore-card-link-text"} ${
                          styles.labelMRegular
                        } ${styles.gradientText}`}
                      >
                        Twitter
                      </div>
                      <img
                        src={chevronRightIcon}
                        alt="chevron-right-icon"
                        className="explore-card-link-icon"
                      />
                    </div>
                  </a>
                </div>
                <div className="explore-card">
                  <div
                    className={`${"explore-card-header"} ${styles.headingS} ${
                      styles.primaryText
                    }`}
                  >
                    Resources
                  </div>
                  <div className="explore-card-subheader-section">
                    <div
                      className={`${"explore-card-subheader"} ${
                        styles.labelMRegular
                      } ${styles.secondaryText}`}
                    >
                      Do a deep dive and learn about Radar.
                      <br />
                      Find out more about all our cool features.
                    </div>
                  </div>
                  {/* <a href="" target="_blank" style={{ textDecoration: "none" }}> */}
                  <div className="explore-card-link-section">
                    <div className="explore-card-link">
                      <div
                        className={`${"explore-card-link-text"} ${
                          styles.labelMRegular
                        } ${styles.secondaryText}`}
                      >
                        Docs
                      </div>
                      {/* <img
                        src={chevronRightIcon}
                        alt="chevron-right-icon"
                        className="explore-card-link-icon"
                      /> */}
                    </div>
                    <div
                      className={`${"explore-card-cs-label"} ${
                        styles.lightGradientBg
                      }`}
                    >
                      <div
                        className={`${styles.gradientText} ${styles.labelSBold}`}
                      >
                        Coming soon!
                      </div>
                    </div>
                  </div>
                  {/* </a> */}
                  <a
                    href="https://radar.blog/the-defi-liquidity-revolution-is-here-join-the-radar-platform-now-341f5c8e9201"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="explore-card-link">
                      <div
                        className={`${"explore-card-link-text"} ${
                          styles.labelMRegular
                        } ${styles.gradientText}`}
                      >
                        Tutorials
                      </div>
                      <img
                        src={chevronRightIcon}
                        alt="chevron-right-icon"
                        className="explore-card-link-icon"
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div
                className={`${"explore-header2"} ${styles.headingXXL} ${
                  styles.primaryText
                }`}
              >
                Explore Radar App
              </div>
              <div className="explore-cards-section">
                <div className="explore-card learn-sect">
                  <div className="explore-card-header-section">
                    <img
                      src={multiplyIcon}
                      alt="explore-radar-icon"
                      className="explore-card-header-icon"
                    />
                    <div
                      className={`${"explore-card-header"} ${styles.headingS} ${
                        styles.primaryText
                      }`}
                    >
                      Multiply
                    </div>
                  </div>
                  <div className="explore-card-subheader-section">
                    <div
                      className={`${"explore-card-subheader"} ${
                        styles.labelMRegular
                      } ${styles.secondaryText}`}
                    >
                      Multiply your exposure to your favorite crypto assets
                      while boosting the yield exponentially
                    </div>
                  </div>
                  <a href="https://app.radar.global/multiply" target="_blank" style={{ textDecoration: "none" }}>
                    <div className="mob-explore-card-link">
                      <div
                        className={`${"explore-card-link-text"} ${
                          styles.labelMRegular
                        } ${styles.gradientText}`}
                      >
                        Connect wallet
                      </div>
                      <img
                        src={chevronRightIcon}
                        alt="chevron-right-icon"
                        className="mob-explore-card-link-icon"
                      />
                    </div>
                  </a>
                  {/* <div
                    className={`${"mob-explore-cs-label"} ${
                      styles.lightGradientBg
                    }`}
                  >
                    <div
                      className={`${"mob-explore-cs-label-text"} ${
                        styles.gradientText
                      } ${styles.labelSBold}`}
                    >
                      Coming soon
                    </div>
                  </div> */}
                </div>
                <div className="explore-card learn-sect">
                  <div className="explore-card-header-section">
                    <img
                      src={farmIcon}
                      alt="explore-radar-icon"
                      className="explore-card-header-icon"
                    />
                    <div
                      className={`${"explore-card-header"} ${styles.headingS} ${
                        styles.primaryText
                      }`}
                    >
                      Farm
                    </div>
                  </div>
                  <div className="explore-card-subheader-section">
                    <div
                      className={`${"explore-card-subheader"} ${
                        styles.labelMRegular
                      } ${styles.secondaryText}`}
                    >
                      RADAR and USDR holders have access to a diverse set of
                      yield farming opportunities
                    </div>
                  </div>
                  <a href="https://app.radar.global/farm" target="_blank" style={{ textDecoration: "none" }}>
                    <div className="mob-explore-card-link">
                      <div
                        className={`${"explore-card-link-text"} ${
                          styles.labelMRegular
                        } ${styles.gradientText}`}
                      >
                        Connect wallet
                      </div>
                      <img
                        src={chevronRightIcon}
                        alt="chevron-right-icon"
                        className="mob-explore-card-link-icon"
                      />
                    </div>
                  </a>
                  {/* <div
                    className={`${"mob-explore-cs-label"} ${
                      styles.lightGradientBg
                    }`}
                  >
                    <div
                      className={`${"mob-explore-cs-label-text"} ${
                        styles.gradientText
                      } ${styles.labelSBold}`}
                    >
                      Coming soon
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="explore-cards-section second">
                <div className="explore-card learn-sect">
                  <div className="explore-card-header-section">
                    <img
                      src={stabilizerIcon}
                      alt="explore-radar-icon"
                      className="explore-card-header-icon"
                    />
                    <div
                      className={`${"explore-card-header"} ${styles.headingS} ${
                        styles.primaryText
                      }`}
                    >
                      Stabilizer
                    </div>
                  </div>
                  <div className="explore-card-subheader-section">
                    <div
                      className={`${"explore-card-subheader"} ${
                        styles.labelMRegular
                      } ${styles.secondaryText}`}
                    >
                      Mint USDR by depositing traditional stablecoins and engage
                      in risk-free arbitrage
                    </div>
                  </div>
                  <a href="https://app.radar.global/stabilizer" target="_blank" style={{ textDecoration: "none" }}>
                    <div className="mob-explore-card-link">
                      <div
                        className={`${"explore-card-link-text"} ${
                          styles.labelMRegular
                        } ${styles.gradientText}`}
                      >
                        Connect wallet
                      </div>
                      <img
                        src={chevronRightIcon}
                        alt="chevron-right-icon"
                        className="mob-explore-card-link-icon"
                      />
                    </div>
                  </a>
                  {/* <div
                    className={`${"mob-explore-cs-label"} ${
                      styles.lightGradientBg
                    }`}
                  >
                    <div
                      className={`${"mob-explore-cs-label-text"} ${
                        styles.gradientText
                      } ${styles.labelSBold}`}
                    >
                      Coming soon
                    </div>
                  </div> */}
                </div>
                <div className="explore-card learn-sect">
                  <div className="explore-card-header-section">
                    <img
                      src={stakeIcon}
                      alt="explore-radar-icon"
                      className="explore-card-header-icon"
                    />
                    <div
                      className={`${"explore-card-header"} ${styles.headingS} ${
                        styles.primaryText
                      }`}
                    >
                      Stake
                    </div>
                  </div>
                  <div className="explore-card-subheader-section">
                    <div
                      className={`${"explore-card-subheader"} ${
                        styles.labelMRegular
                      } ${styles.secondaryText}`}
                    >
                      Stake your RADAR to earn token rewards and participate in
                      the governance of the ecosystem
                    </div>
                  </div>
                  <a href="https://app.radar.global/stake" target="_blank" style={{ textDecoration: "none" }}>
                    <div className="mob-explore-card-link">
                      <div
                        className={`${"explore-card-link-text"} ${
                          styles.labelMRegular
                        } ${styles.gradientText}`}
                      >
                        Connect wallet
                      </div>
                      <img
                        src={chevronRightIcon}
                        alt="chevron-right-icon"
                        className="mob-explore-card-link-icon"
                      />
                    </div>
                  </a>
                  {/* <div
                    className={`${"mob-explore-cs-label"} ${
                      styles.lightGradientBg
                    }`}
                  >
                    <div
                      className={`${"mob-explore-cs-label-text"} ${
                        styles.gradientText
                      } ${styles.labelSBold}`}
                    >
                      Coming soon
                    </div>
                  </div> */}
                </div>
              </div>
              <Footer {...this.props} />
            </div>
          </div>
        ) : (
          <div className="mob-explore-wrapper">
            <div className="mob-explore-radar-circle-svg-section">
              <img
                src={radarLines}
                alt="radar-lines-svg"
                className="mob-explore-radar-circle-svg"
              />
            </div>
            <div className="mob-explore-layout">
              <div className="mobile-explore-gradient-overlay" />
              {/* <div className="mobile-explore-orange-overlay" />
              <div className="mobile-explore-pink-overlay" />
              <div className="mobile-explore-yellow-overlay" /> */}
            </div>
            <div className="mob-explore-content">
              <div
                className={`${"mob-explore-header"} ${styles.headingXXL} ${
                  styles.primaryText
                }`}
              >
                Have some questions?
              </div>
              <div className="mob-explore-cards-section">
                <div className="mob-explore-card">
                  <div
                    className={`${"mob-explore-card-header-wi"} ${
                      styles.headingS
                    } ${styles.primaryText}`}
                  >
                    Support
                  </div>
                  <div
                    className={`${"mob-explore-card-subheader"} ${
                      styles.labelMRegular
                    } ${styles.secondaryText}`}
                  >
                    Contact us if you have any questions.
                  </div>
                  <Mailto
                    email="contact@radar.global"
                    subject=""
                    body=""
                    style={{ textDecoration: "none" }}
                  >
                    <div className="mob-explore-card-link">
                      <div
                        className={`${"explore-card-link-text"} ${
                          styles.labelMRegular
                        } ${styles.gradientText}`}
                      >
                        Contact us
                      </div>
                      <img
                        src={chevronRightIcon}
                        alt="chevron-right-icon"
                        className="mob-explore-card-link-icon"
                      />
                    </div>
                  </Mailto>
                  <a
                    href="https://t.me/radarprotocol"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="mob-explore-card-link">
                      <div
                        className={`${"mob-explore-card-link-text"} ${
                          styles.labelMRegular
                        } ${styles.gradientText}`}
                      >
                        Telegram
                      </div>
                      <img
                        src={chevronRightIcon}
                        alt="chevron-right-icon"
                        className="mob-explore-card-link-icon"
                      />
                    </div>
                  </a>
                  <a
                    href="https://twitter.com/radarprotocol"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="mob-explore-card-link">
                      <div
                        className={`${"mob-explore-card-link-text"} ${
                          styles.labelMRegular
                        } ${styles.gradientText}`}
                      >
                        Twitter
                      </div>
                      <img
                        src={chevronRightIcon}
                        alt="chevron-right-icon"
                        className="mob-explore-card-link-icon"
                      />
                    </div>
                  </a>
                </div>
                <div className="mob-explore-card">
                  <div
                    className={`${"mob-explore-card-header wi"} ${
                      styles.headingS
                    } ${styles.primaryText}`}
                  >
                    Resources
                  </div>
                  <div
                    className={`${"mob-explore-card-subheader"} ${
                      styles.labelMRegular
                    } ${styles.secondaryText}`}
                  >
                    Do a deep dive and learn about Radar.
                    <br />
                    Find out more about all our cool features.
                  </div>
                  {/* <a href="" target="_blank" style={{ textDecoration: "none" }}> */}
                  <div className="explore-card-link-section">
                    <div className="mob-explore-card-link">
                      <div
                        className={`${"mob-explore-card-link-text"} ${
                          styles.labelMRegular
                        } ${styles.secondaryText}`}
                      >
                        Docs
                      </div>
                      {/* <img
                        src={chevronRightIcon}
                        alt="chevron-right-icon"
                        className="mob-explore-card-link-icon"
                      /> */}
                    </div>
                    <div
                      className={`${"mob-explore-card-cs-label"} ${
                        styles.lightGradientBg
                      }`}
                    >
                      <div
                        className={`${styles.gradientText} ${styles.labelSBold}`}
                      >
                        Coming soon!
                      </div>
                    </div>
                  </div>
                  {/* </a> */}
                  <a
                    href="https://radar.blog/the-defi-liquidity-revolution-is-here-join-the-radar-platform-now-341f5c8e9201"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="mob-explore-card-link">
                      <div
                        className={`${"mob-explore-card-link-text"} ${
                          styles.labelMRegular
                        } ${styles.gradientText}`}
                      >
                        Tutorials
                      </div>
                      <img
                        src={chevronRightIcon}
                        alt="chevron-right-icon"
                        className="mob-explore-card-link-icon"
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div
                className={`${"mob-explore-header2"} ${styles.headingXXL} ${
                  styles.primaryText
                }`}
              >
                Explore <br />
                Radar App
              </div>
              <div className="mob-explore-cards-section">
                <div className="mob-explore-card learn-sect">
                  <div className="mob-explore-card-header-section">
                    <img
                      src={multiplyIcon}
                      alt="explore-radar-icon"
                      className="mob-explore-card-header-icon"
                    />
                    <div
                      className={`${"mob-explore-card-header"} ${
                        styles.headingS
                      } ${styles.primaryText}`}
                    >
                      Multiply
                    </div>
                  </div>
                  <div className="mob-explore-card-subheader-section">
                    <div
                      className={`${"mob-explore-card-subheader"} ${
                        styles.labelMRegular
                      } ${styles.secondaryText}`}
                    >
                      Multiply your exposure to your favorite crypto assets
                      while boosting the yield exponentially
                    </div>
                  </div>
                  <a href="https://app.radar.global/multiply" target="_blank" style={{ textDecoration: "none" }}>
                    <div className="mob-explore-card-link">
                      <div
                        className={`${"explore-card-link-text"} ${
                          styles.labelMRegular
                        } ${styles.gradientText}`}
                      >
                        Connect wallet
                      </div>
                      <img
                        src={chevronRightIcon}
                        alt="chevron-right-icon"
                        className="mob-explore-card-link-icon"
                      />
                    </div>
                  </a>
                  {/* <div
                    className={`${"mob-explore-cs-label"} ${
                      styles.lightGradientBg
                    }`}
                  >
                    <div
                      className={`${"mob-explore-cs-label-text"} ${
                        styles.gradientText
                      } ${styles.labelSBold}`}
                    >
                      Coming soon
                    </div>
                  </div> */}
                </div>
                <div className="mob-explore-card learn-sect">
                  <div className="mob-explore-card-header-section">
                    <img
                      src={farmIcon}
                      alt="explore-radar-icon"
                      className="mob-explore-card-header-icon"
                    />
                    <div
                      className={`${"mob-explore-card-header"} ${
                        styles.headingS
                      } ${styles.primaryText}`}
                    >
                      Farm
                    </div>
                  </div>
                  <div className="mob-explore-card-subheader-section">
                    <div
                      className={`${"mob-explore-card-subheader"} ${
                        styles.labelMRegular
                      } ${styles.secondaryText}`}
                    >
                      RADAR and USDR holders have access to a diverse set of
                      yield farming opportunities
                    </div>
                  </div>
                  <a href="https://app.radar.global/farm" target="_blank" style={{ textDecoration: "none" }}>
                    <div className="mob-explore-card-link">
                      <div
                        className={`${"explore-card-link-text"} ${
                          styles.labelMRegular
                        } ${styles.gradientText}`}
                      >
                        Connect wallet
                      </div>
                      <img
                        src={chevronRightIcon}
                        alt="chevron-right-icon"
                        className="mob-explore-card-link-icon"
                      />
                    </div>
                  </a>
                  {/* <div
                    className={`${"mob-explore-cs-label"} ${
                      styles.lightGradientBg
                    }`}
                  >
                    <div
                      className={`${"mob-explore-cs-label-text"} ${
                        styles.gradientText
                      } ${styles.labelSBold}`}
                    >
                      Coming soon
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="mob-explore-cards-section second">
                <div className="mob-explore-card learn-sect">
                  <div className="mob-explore-card-header-section">
                    <img
                      src={stabilizerIcon}
                      alt="explore-radar-icon"
                      className="mob-explore-card-header-icon"
                    />
                    <div
                      className={`${"mob-explore-card-header"} ${
                        styles.headingS
                      } ${styles.primaryText}`}
                    >
                      Stabilizer
                    </div>
                  </div>
                  <div className="mob-explore-card-subheader-section">
                    <div
                      className={`${"mob-explore-card-subheader"} ${
                        styles.labelMRegular
                      } ${styles.secondaryText}`}
                    >
                      Mint USDR by depositing traditional stablecoins and engage
                      in risk-free arbitrage
                    </div>
                  </div>
                  <a href="https://app.radar.global/stabilizer" target="_blank" style={{ textDecoration: "none" }}>
                    <div className="mob-explore-card-link">
                      <div
                        className={`${"explore-card-link-text"} ${
                          styles.labelMRegular
                        } ${styles.gradientText}`}
                      >
                        Connect wallet
                      </div>
                      <img
                        src={chevronRightIcon}
                        alt="chevron-right-icon"
                        className="mob-explore-card-link-icon"
                      />
                    </div>
                  </a>
                  {/* <div
                    className={`${"mob-explore-cs-label"} ${
                      styles.lightGradientBg
                    }`}
                  >
                    <div
                      className={`${"mob-explore-cs-label-text"} ${
                        styles.gradientText
                      } ${styles.labelSBold}`}
                    >
                      Coming soon
                    </div>
                  </div> */}
                </div>
                <div className="mob-explore-card learn-sect">
                  <div className="mob-explore-card-header-section">
                    <img
                      src={stakeIcon}
                      alt="explore-radar-icon"
                      className="mob-explore-card-header-icon"
                    />
                    <div
                      className={`${"mob-explore-card-header"} ${
                        styles.headingS
                      } ${styles.primaryText}`}
                    >
                      Stake
                    </div>
                  </div>
                  <div className="mob-explore-card-subheader-section">
                    <div
                      className={`${"mob-explore-card-subheader"} ${
                        styles.labelMRegular
                      } ${styles.secondaryText}`}
                    >
                      Stake your RADAR to earn token rewards and participate in
                      the governance of the ecosystem
                    </div>
                  </div>
                  <a href="https://app.radar.global/stake" target="_blank" style={{ textDecoration: "none" }}>
                    <div className="mob-explore-card-link">
                      <div
                        className={`${"explore-card-link-text"} ${
                          styles.labelMRegular
                        } ${styles.gradientText}`}
                      >
                        Connect wallet
                      </div>
                      <img
                        src={chevronRightIcon}
                        alt="chevron-right-icon"
                        className="mob-explore-card-link-icon"
                      />
                    </div>
                  </a>
                  {/* <div
                    className={`${"mob-explore-cs-label"} ${
                      styles.lightGradientBg
                    }`}
                  >
                    <div
                      className={`${"mob-explore-cs-label-text"} ${
                        styles.gradientText
                      } ${styles.labelSBold}`}
                    >
                      Coming soon
                    </div>
                  </div> */}
                </div>
              </div>
              <Footer {...this.props} />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default ExploreSection;
