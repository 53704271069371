import React, { Component } from "react";
import { connect } from "react-redux";
import config from "../../config/config.json";

import radarLogo from "../../assets/logos/radar.svg";
import discordIcon from "../../assets/SVGs/socials/discord.svg";
import linkedinIcon from "../../assets/SVGs/socials/linkedin.svg";
import telegramIcon from "../../assets/SVGs/socials/telegram.svg";
import twitterIcon from "../../assets/SVGs/socials/twitter.svg";
import styles from "../../styles/index.module.css";
import "./footer.css";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.toPage = this.toPage.bind(this);
  }

  toPage = (path) => {
    this.props.history.push(path);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  toPageMobile = (path) => {
    this.props.history.push(path);
    window.scrollTo({
      top: 0,
      left: 0,
    });
  };

  render() {
    const Mailto = ({ email, subject = "", body = "", children }) => {
      let params = subject || body ? "?" : "";
      if (subject) params += `subject=${encodeURIComponent(subject)}`;
      if (body)
        params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

      return <a href={`mailto:${email}${params}`}>{children}</a>;
    };
    var width = window.innerWidth;
    return (
      <>
        {width > 1299 ? (
          <div className="footer-wrapper">
            <div className="footer-content">
              <div className="footer-layout">
                <div className="footer-col">
                  <img
                    src={radarLogo}
                    alt="radar-logo"
                    className="footer-radar-logo"
                  />
                  <div className="footer-socials-section">
                    <a
                      href="https://t.me/radarprotocol"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        src={telegramIcon}
                        alt="telegram"
                        className="footer-social-icon"
                      />
                    </a>
                    <a
                      href="https://twitter.com/radarprotocol"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        src={twitterIcon}
                        alt="twitter"
                        className="footer-social-icon"
                      />
                    </a>
                    <a
                      href="https://discord.com/invite/radar"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        src={discordIcon}
                        alt="discord"
                        className="footer-social-icon"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/radarprotocol/"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        src={linkedinIcon}
                        alt="linkedin"
                        className="footer-social-icon"
                      />
                    </a>
                  </div>
                </div>
                <div className="footer-col">
                  <div
                    className={`${styles.headingXS} ${
                      styles.primaryText
                    } ${"footer-col-header"}`}
                  >
                    Features
                  </div>
                  {Object.keys(config.footer["features"]).map((i) => (
                    <div>
                      <a
                        href={config.footer["features"][i].link}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                        className={`${styles.paragraphM} ${
                          styles.primaryText
                        } ${"footer-link-text soon"}`}
                      >
                        <span>{config.footer["features"][i].text}</span>
                      </a>
                    </div>
                  ))}
                </div>
                <div className="footer-col">
                  <div
                    className={`${styles.headingXS} ${
                      styles.primaryText
                    } ${"footer-col-header"}`}
                  >
                    Resources
                  </div>
                  <div
                    className={`${styles.paragraphM} ${
                      styles.primaryText
                    } ${"footer-link-text"}`}
                    onClick={() => this.toPage("/terms-and-conditions")}
                  >
                    Terms and Conditions
                  </div>
                </div>
                <div className="footer-col">
                  <div
                    className={`${styles.headingXS} ${
                      styles.primaryText
                    } ${"footer-col-header"}`}
                  >
                    Contact
                  </div>
                  <Mailto
                    email="contact@radar.global"
                    subject=""
                    body=""
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      className={`${styles.paragraphM} ${
                        styles.primaryText
                      } ${"footer-link-text"}`}
                    >
                      contact@radar.global
                    </div>
                  </Mailto>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="mob-footer-wrapper">
            <div className="mob-footer-content">
              <div className="mob-footer-layout">
                <div className="mob-footer-col">
                  <img
                    src={radarLogo}
                    alt="radar-logo"
                    className="mob-footer-radar-logo"
                  />
                  <div className="mob-footer-socials-section">
                    <a
                      href="https://t.me/radarprotocol"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        src={telegramIcon}
                        alt="telegram"
                        className="mob-footer-social-icon"
                      />
                    </a>
                    <a
                      href="https://twitter.com/radarprotocol"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        src={twitterIcon}
                        alt="twitter"
                        className="mob-footer-social-icon"
                      />
                    </a>
                    <a
                      href="https://discord.com/invite/radar"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        src={discordIcon}
                        alt="discord"
                        className="mob-footer-social-icon"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/radarprotocol/"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        src={linkedinIcon}
                        alt="linkedin"
                        className="mob-footer-social-icon"
                      />
                    </a>
                  </div>
                </div>
                <div className="mob-footer-col">
                  <div
                    className={`${styles.headingXS} ${
                      styles.primaryText
                    } ${"mob-footer-col-header"}`}
                  >
                    Features
                  </div>
                  {Object.keys(config.footer["features"]).map((i) => (
                    <div>
                      <a
                        href={config.footer["features"][i].link}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                        className={`${styles.paragraphM} ${
                          styles.primaryText
                        } ${"mob-footer-link-text soon"}`}
                      >
                        <span>{config.footer["features"][i].text}</span>
                      </a>
                    </div>
                  ))}
                </div>
                <div className="mob-footer-col">
                  <div
                    className={`${styles.headingXS} ${
                      styles.primaryText
                    } ${"mob-footer-col-header"}`}
                  >
                    Resources
                  </div>
                  <div
                    className={`${styles.paragraphM} ${
                      styles.primaryText
                    } ${"mob-footer-link-text"}`}
                    onClick={() => this.toPageMobile("/terms-and-conditions")}
                  >
                    Terms and Conditions
                  </div>
                </div>
                <div className="mob-footer-col">
                  <div
                    className={`${styles.headingXS} ${
                      styles.primaryText
                    } ${"mob-footer-col-header"}`}
                  >
                    Contact
                  </div>
                  <Mailto
                    email="contact@radar.global"
                    subject=""
                    body=""
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      className={`${styles.paragraphM} ${
                        styles.primaryText
                      } ${"mob-footer-link-text"}`}
                    >
                      contact@radar.global
                    </div>
                  </Mailto>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
