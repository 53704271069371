import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import store from './redux/store.js'

import HomePage from './views/home-page/HomePage';
import TermsAndConditionsPage from "./views/terms-and-conditions/TermsAndConditionsPage";

const app = (
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact component={HomePage} />
                    <Route path="/terms-and-conditions" exact component={TermsAndConditionsPage} />
                </Switch>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
