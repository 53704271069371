import React, { Component } from "react";
import { connect } from "react-redux";

import radarLines from "../../assets/SVGs/radar-circle.svg";
import styles from "../../styles/index.module.css";
import "./hero.css";

class Hero extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.toPage = this.toPage.bind(this);
  }

  toPage = (path) => {
    this.props.history.push(path);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  render() {
    var width = window.innerWidth;
    return (
      <>
        {width > 1299 ? (
          <div className="hero-wrapper">
            <div className="hero-radar-lines-svg-section">
              <img
                src={radarLines}
                alt="radar-lines-svg"
                className="hero-radar-lines-svg"
              />
            </div>
            <div className="hero-layout">
              {/* <div className="hero-pink-overlay" />
              <div className="hero-yellow-overlay" />
              <div className="hero-purple-overlay" />
              <div className="hero-orange-overlay" /> */}
            </div>
            <div className="hero-content">
              <div className="hero-text-section">
                <div className={`${styles.headingXXXL} ${styles.primaryText}`}>
                  Unlock your liquidity
                </div>
                <div
                  className={`${"hero-text-secondary"} ${styles.secondaryText}`}
                >
                  Tap into the hidden value of your crypto without selling any
                  of your assets <br />
                  Borrow at 0% interest, earn yield & multiply your
                  opportunities
                </div>
                <a
                  href="https://app.radar.global/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className={`${styles.button} ${"hero-cw-button soon"} ${
                      styles.gradientBg
                    } ${styles.labelMRegular} ${styles.whiteText}`}
                  >
                    {/* <span>Launch app</span> */}
                    Launch app
                  </div>
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="mob-hero-wrapper">
            <div className="mob-hero-radar-lines-svg-section">
              <img
                src={radarLines}
                alt="radar-lines-svg"
                className="mob-hero-radar-lines-svg"
              />
            </div>
            <div className="mob-hero-layout">
              {/* <div className="mob-hero-pink-overlay" />
              <div className="mob-hero-yellow-overlay" />
              <div className="mob-hero-purple-overlay" />
              <div className="mob-hero-orange-overlay" /> */}
            </div>
            <div className="mob-hero-content">
              <div className="mob-hero-text-section">
                <div className={`${styles.headingXL} ${styles.primaryText}`}>
                  Unlock your liquidity
                </div>
                <div
                  className={`${"mob-hero-text-secondary"} ${
                    styles.secondaryText
                  }`}
                >
                  Tap into the hidden value of your crypto without selling any
                  of your assets <br />
                  Borrow at 0% interest, earn yield & multiply your
                  opportunities
                </div>
                <a href="https://app.radar.global/" target="_blank" style={{ textDecoration: "none" }}>
                <div
                  className={`${styles.button} ${"mob-hero-cw-button soon"} ${
                    styles.gradientBg
                  } ${styles.labelMRegular} ${styles.whiteText}`}
                >
                  {/* <span>Launch app</span> */}
                  Launch app
                </div>
                </a>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Hero);
