import React, { Component } from "react";

import Header from "../../layouts/header/Header";
import Footer from "../../layouts/footer/Footer";

import styles from "../../styles/index.module.css";
import "./termsAndConditions.css";

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    var width = window.innerWidth;
    return (
      <>
        {width > 1299 ? (
          <div className={styles.body}>
            <Header {...this.props} state={this.state} />
            <div className="terms-wrapper">
              <div className="terms-layout">
                <div className="terms-pink-overlay" />
                <div className="terms-yellow-overlay" />
                <div className="terms-purple-overlay" />
                <div className="terms-orange-overlay" />
              </div>
              <div className="terms-content">
                <div
                  className={`${styles.headingXXXL} ${
                    styles.primaryText
                  } ${"terms-header"}`}
                >
                  Terms and Conditions
                </div>
                <div
                  className={`${styles.paragraphL} ${
                    styles.secondaryText
                  } ${"terms-text"}`}
                >
                  By using this software, you understand, acknowledge and accept
                  that Radar and/or the underlying software are provided “as is”
                  and without warranties or representations of any kind either
                  expressed or implied.
                </div>
                <Footer {...this.props} state={this.state} />
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.body}>
            <Header {...this.props} state={this.state} />
            <div className="mob-terms-wrapper">
              <div className="mob-terms-layout">
                <div className="mob-terms-pink-overlay" />
                <div className="mob-terms-yellow-overlay" />
                <div className="mob-terms-purple-overlay" />
                <div className="mob-terms-orange-overlay" />
              </div>
              <div className="mob-terms-content">
                <div
                  className={`${styles.headingXL} ${
                    styles.primaryText
                  } ${"mob-terms-header"}`}
                >
                  Terms and Conditions
                </div>
                <div
                  className={`${styles.paragraphL} ${
                    styles.secondaryText
                  } ${"mob-terms-text"}`}
                >
                  By using this software, you understand, acknowledge and accept
                  that Radar and/or the underlying software are provided “as is”
                  and without warranties or representations of any kind either
                  expressed or implied.
                </div>
                <Footer {...this.props} state={this.state} />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default HomePage;
