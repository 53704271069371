import React, { Component } from "react";
import axios from "axios";
import {
  getRadarTVL,
  getStakingTotalRadarDeposited,
} from "../../assets/lib/funcs";
import { getRadarCirculatingSupply } from "../../assets/utils/utils";

import Header from "../../layouts/header/Header";
import Hero from "../../components/hero/Hero";
import BorrowMultiplySection from "../../components/borrow-multiply/BorrowMultiplySection";
import ExploreSection from "../../components/explore/ExploreSection";
import Loader from "../../components/loader/Loader";

import whitepaper from "../../assets/docs/whitepaper.pdf";
import appVideo from "../../assets/videos/app-video.gif";
import appImage from "../../assets/PNGs/app-image.png";
import whiteOverlay from "../../assets/PNGs/white-overlay.png";
import styles from "../../styles/index.module.css";

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      radarTVL: "",
      radarTVLLoading: false,

      radarStaked: "",
      radarStakedLoading: false,

      APY: "",
      APYLoading: false,
    };
  }

  getRadarTVLFunc = async () => {
    this.setState({
      radarTVLLoading: true,
    });
    let radarTVL = await getRadarTVL();
    this.setState({
      radarTVL: radarTVL.toLocaleString(undefined, {
        maximumFractionDigits: 0,
      }),
      radarTVLLoading: false,
    });
  };

  getRadarStakedFunc = async () => {
    await this.setState({
      radarStakedLoading: false,
    });
    let totalRadarDepositedOnETH = await getStakingTotalRadarDeposited("ETH");
    let totalRadarDepositedOnBSC = await getStakingTotalRadarDeposited("BSC");
    let totalRadarDeposited =
      parseFloat(totalRadarDepositedOnETH) +
      parseFloat(totalRadarDepositedOnBSC);
    let circulatingRadarSupply = await getRadarCirculatingSupply();
    let radarStakedPercent =
      (parseFloat(totalRadarDeposited) / parseFloat(circulatingRadarSupply)) *
      100;
    await this.setState({
      radarStaked: parseFloat(radarStakedPercent).toFixed(2),
      radarStakedLoading: false,
    });
  };

  renderLoading() {
    return <Loader {...this.props} />;
  }

  componentDidMount() {
    this.getRadarTVLFunc();
    this.getRadarStakedFunc();
  }

  render() {
    var width = window.innerWidth;
    return (
      <>
        <div className={styles.body}>
          <Header {...this.props} state={this.state} />
          <Hero {...this.props} state={this.state} />
          {width > 1299 ? (
            <div className={styles.mainContent}>
              <div className={styles.whiteOverlaySection}>
                <img
                  src={whiteOverlay}
                  alt="white-overlay"
                  className={styles.whiteOverlayImage}
                />
              </div>
              <div className={styles.tutorialSection}>
                <img
                  src={appVideo}
                  className={styles.tutorialVideo}
                  alt="app-video"
                />
              </div>
              <div className={styles.generalInfoSection}>
                <div className={styles.generalInfoLayout}>
                  <div className={styles.generalInfoCol}>
                    {this.state.radarTVLLoading === false ? (
                      <div
                        className={`${styles.headingXXL} ${styles.gradientText}`}
                      >
                        ${this.state.radarTVL}
                      </div>
                    ) : (
                      <>{this.renderLoading()}</>
                    )}
                    <div
                      className={`${styles.labelSRegular} ${styles.secondaryText}`}
                    >
                      TVL
                    </div>
                  </div>
                  <div className={styles.generalInfoCol}>
                    {this.state.radarStakedLoading === false ? (
                      <div
                        className={`${styles.headingXXL} ${styles.gradientText}`}
                      >
                        {this.state.radarStaked}%
                      </div>
                    ) : (
                      <>{this.renderLoading()}</>
                    )}
                    <div
                      className={`${styles.labelSRegular} ${styles.secondaryText}`}
                    >
                      RADAR staked
                    </div>
                  </div>
                  <div className={styles.generalInfoCol}>
                    {this.state.APYLoading === false && (
                      <div
                        className={`${styles.headingXXL} ${styles.gradientText}`}
                      >
                        78%
                      </div>
                    )}
                    <div
                      className={`${styles.labelSRegular} ${styles.secondaryText}`}
                    >
                      APY up to
                    </div>
                  </div>
                </div>
              </div>
              <BorrowMultiplySection {...this.props} />
              {/* <a href={whitepaper}>whitepaper here</a> */}
              <ExploreSection {...this.props} />
            </div>
          ) : (
            <div className={styles.mobMainContent}>
              <div className={styles.mobWhiteOverlaySection}>
                <img
                  src={whiteOverlay}
                  alt="white-overlay"
                  className={styles.mobWhiteOverlayImage}
                />
              </div>
              <div className={styles.mobTutorialSection}>
                <img
                  src={appImage}
                  className={styles.mobTutorialVideo}
                  alt="app-image"
                />
              </div>
              <div className={styles.generalInfoSection}>
                <div className={styles.generalInfoLayout}>
                  <div className={styles.generalInfoCol}>
                    {this.state.radarTVLLoading === false ? (
                      <div
                        className={`${styles.headingM} ${styles.gradientText}`}
                      >
                        ${this.state.radarTVL}
                      </div>
                    ) : (
                      <>{this.renderLoading()}</>
                    )}
                    <div
                      className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                    >
                      TVL
                    </div>
                  </div>
                  <div className={styles.generalInfoCol}>
                    {this.state.radarStakedLoading === false ? (
                      <div
                        className={`${styles.headingM} ${styles.gradientText}`}
                      >
                        {this.state.radarStaked}%
                      </div>
                    ) : (
                      <>{this.renderLoading()}</>
                    )}
                    <div
                      className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                    >
                      RADAR staked
                    </div>
                  </div>
                  <div className={styles.generalInfoCol}>
                    {this.state.APYLoading === false && (
                      <div
                        className={`${styles.headingM} ${styles.gradientText}`}
                      >
                        78%
                      </div>
                    )}
                    <div
                      className={`${styles.labelXSRegular} ${styles.secondaryText}`}
                    >
                      APY up to
                    </div>
                  </div>
                </div>
              </div>
              <BorrowMultiplySection {...this.props} />
              <ExploreSection {...this.props} />
            </div>
          )}
        </div>
      </>
    );
  }
}

export default HomePage;
