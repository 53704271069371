import axios from "axios";
import { ethers } from 'ethers';
import { getRadarTotalSupply, getEthersProvider, validateNetwork } from '../utils/utils';
import config from "../../config/config.json";

import { RadarStakingInterface } from '../interfaces';


export const getRadarTVL = async () => {
  try {
    var data = await axios.get(`https://api.radar.global/v1/platformTVL`);
    var tvl = await data.data;
    return tvl;
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const getStakingTotalRadarDeposited = async (network) => {
  validateNetwork(network);
  try {
      var provider = await getEthersProvider(config.networks[network].rpc, "jsonrpc");
      var staking = new ethers.Contract(
          config.staking[network].address,
          RadarStakingInterface,
          provider
      );

      var td = await staking.totalSupply();
      td /= 10**18;
      return td;
  } catch(e) {
      console.log(e)
      return 0;
  }

}