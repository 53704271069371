import axios from "axios";
import { ethers, BigNumber } from "ethers";
import config from "../../config/config.json";
import { IERC20Interface, UniswapPairInterface } from "../interfaces";

export const getLPPrice = async (lp, network) => {
  var provider = await getEthersProvider(
    config.networks[network].rpc,
    "jsonrpc"
  );
  try {
    var pair = new ethers.Contract(lp, UniswapPairInterface, provider);

    var token0 = await pair.token0();
    var token1 = await pair.token1();
    token0 = token0.toLowerCase();
    token1 = token1.toLowerCase();

    var token0Decimals = config.tokenDecimals[network][token0];
    var token1Decimals = config.tokenDecimals[network][token1];

    var reserves = await pair.getReserves();
    var reserve0 = reserves.reserve0 / 10 ** token0Decimals;
    var reserve1 = reserves.reserve1 / 10 ** token1Decimals;

    var lpDecimals = await pair.decimals();
    var lpTotalSupply = await pair.totalSupply();

    lpTotalSupply /= 10 ** lpDecimals;

    var token0price = await coingeckoGetPrice(
      config.coingecko.IDS[network][token0]
    );
    var token1price = await coingeckoGetPrice(
      config.coingecko.IDS[network][token1]
    );

    var price =
      (reserve0 * token0price + reserve1 * token1price) / lpTotalSupply;
    return price;
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const validateNetwork = (network) => {
  if (!Object.keys(config.networks).includes(network))
    throw Error("Invalid network");
};

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const fullNumber = (x) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
};

export const scaleDecimals = (x, decimals) => {
  x *= 10 ** 8;
  x = parseInt(x);
  var res = BigNumber.from(x).mul(10 ** (decimals - 8));
  return res;
};

export const getEthersProvider = async (onboardProvider, type = "jsonrpc") => {
  try {
    if (type == "jsonrpc") {
      var np = new ethers.providers.JsonRpcProvider(onboardProvider);
      return np;
    } else if (type == "web3") {
      // Debugging
      // if (onboardProvider instanceof ethers.Wallet) {
      //     var prov = new ethers.providers.JsonRpcProvider("http://127.0.0.1:8545");
      //     var np = onboardProvider.connect(prov);
      //     return np;
      // }
      var np = new ethers.providers.Web3Provider(onboardProvider);
      var signer = await np.getSigner();
      return signer;
    } else {
      throw Error("Invalid provider");
    }
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const coingeckoGetPrice = async (tokenId) => {
  try {
    var data = await axios.get(
      `${config.coingecko.URL}/api/v3/simple/price?ids=${tokenId}&vs_currencies=usd`
    );
    var price = data.data[tokenId].usd;
    return price;
  } catch (e) {
    return 0;
  }
};

export const radarPrice = async () => {
  var ret = await coingeckoGetPrice(config.coingecko.RADAR_API_ID);
  return ret;
};

export const getTokenBalance = async (userAddress, tokenAddress, network) => {
  try {
    var provider = await getEthersProvider(
      config.networks[network].rpc,
      "jsonrpc"
    );
    var token = new ethers.Contract(tokenAddress, IERC20Interface, provider);

    var balance = await token.balanceOf(userAddress);
    return balance;
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const getRadarTotalSupply = async () => {
  try {
    var provider = await getEthersProvider(config.networks.ETH.rpc, "jsonrpc");
    var token = new ethers.Contract(
      config.networks.ETH.RADAR_address,
      IERC20Interface,
      provider
    );

    var totalSupply = await token.totalSupply();
    return totalSupply / 10 ** 18;
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const getRadarCirculatingSupply = async () => {
  try {
    const axios = require('axios');
    console.log("I'm in getRadarCirculatingSupply function");
    var endpointData = await axios.get(
      "https://botocean.com/static-apis/circulating"
    );
    console.log("endpointData: " + endpointData);
    var circulatingSupply = await endpointData.data;
    console.log("endpointData.data: " + endpointData.data);
    return circulatingSupply;
  } catch (e) {
    console.log(e);
    return 0;
  }
};
